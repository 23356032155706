import React, { useState, useEffect } from 'react';
import JourneyTemplateSVG from '@components/atoms/JourneyTemplateSVG/JourneyTemplateSVG';
import OpenMsFormButton from '@components/molecules/OpenMsFormButton/OpenMsFormButton';

const JourneyStageTemplate = ({ data }) => {
  const { stageContentBlocks } = data;
  const [isJourneyRendered, setIsJourneyRendered] = useState(false);

  useEffect(() => {
    setIsJourneyRendered(true);
  }, []);

  return (
    <>
      <JourneyTemplateSVG
        species={data.intro.title}
        stageContentBlocks={stageContentBlocks}
      />
      {isJourneyRendered && (
        <OpenMsFormButton formUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=OskoFRcZoE-1ALqcjkyZPyrIsmwGEu9EpNkZBhIzltBUQTEwRUNXT0MwWk9LQkNBNkFCUDJXNFJONC4u&embed=true" />
      )}
    </>
  );
};

export default JourneyStageTemplate;
