import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import actions from '@state/actions';

import './StageMenu.scss';

const StageMenu = ({ children, species, stageContentBlocks, onClose = null, isOpen = false }) => {
  const stageFlyInRef = useRef(null);
  const dispatch = useDispatch();

  const close = () => {
    if (typeof onClose === `function`) {
      onClose();
    }
  };

  const onKeyPress = (e) => {
    const { keyCode } = e;

    switch (keyCode) {
      case 27: // Escape.
        close();
        break;

      case 9: // Tab.
        // Close the FlyIn when user tabs past the last focusable element in the panel.
        if (!e.shiftKey) {
          const focusableElements = stageFlyInRef.current.querySelectorAll(
            `a[href], button`
          );
          const last = focusableElements[focusableElements.length - 1];
          if (document.activeElement === last) {
            close();
          }
        }
    }
  };

  const visibleClass = isOpen ? `mars-stage-menu--visible` : ``;

  return (
    <aside
      ref={stageFlyInRef}
      aria-expanded={isOpen}
      className={`mars-stage-menu ${visibleClass} mars-elevation mars-padding-x--md mars-padding-y--xl`}
      onKeyDown={onKeyPress}>
      <h3 className="mars-body-large mars-stage-menu--title">{species} parent stages</h3>
      <ul className="mars-links-list">
        {stageContentBlocks.map((stageContentBlock, index) => {
          return <li key={index} className="mars-margin-y--lg"><a className="mars-link mars-link--small mars-body mars-full-width" onClick={() =>
            dispatch(actions.openStage(stageContentBlock))}>{stageContentBlock.title}<MarsIcon icon="right" width="16px" height="16px" className="mars-icon mars-margin-right--xxs" /></a></li>
        })}
      </ul>
      <MarsIcon icon="download" width="12px" height="12px" className="mars-icon mars-margin-right--xxs" />
      <button className="mars-link mars-epsilon mars-margin-y--lg mars-padding--none" onClick={(e) =>
          dispatch(actions.openViewJourney(species))
        }
        title={`Download all the files for the ${species && species.split(` `)[0]} parent journey`}>
        <span className="mars-button__text">Download {species} parent journey</span>
      </button>
      <button onClick={close} aria-label="Close stages" className="mars-button mars-button--wide mars-margin-top--lg">Close stages</button>
      {children}
    </aside>
  );
};

StageMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StageMenu;
