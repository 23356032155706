import React, {useRef, useEffect, useState} from 'react';
import classnames from 'classnames';
import { useBreakpoints, useCurrentWidth } from 'react-breakpoints-hook';
import actions from '@state/actions';
import { useDispatch } from 'react-redux';
import StageMenu from '@components/atoms/StageMenu/StageMenu';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { navigate } from 'gatsby-link';

import DogSVG from '@svgs/dog-journey-graphic.svg';
import CatSVG from '@svgs/cat-journey-graphic.svg';
import MobileDogSVGM from '@svgs/dog-journey-graphic-mobile.svg';
import MobileCatSVGM from '@svgs/cat-journey-graphic-mobile.svg';

import './JourneyTemplateSVG.scss';

const USERPILOT_CAT_TOOLTIPS_IDS = {
  "consideration-tag": `1622703559nLaq2502`,
  "health-concerns-tag": `1622711734lKlz2774`,
  "occasional-experiences-tag": `1622711859cSaz9198`,
  "acquisition-tag": `1622711979nQdv5451`,
  "parting-tag": `1622712141xEkd0937`,
  "early-pet-parenthood-tag": `1622712235dYjg4835`,
  "adjusting-to-older-pets-tag": `1622712334eJgz3443`,
  "everyday-life-tag": `1622712413bAwo1339`
}

const USERPILOT_DOG_TOOLTIPS_IDS = {
  "consideration-tag": `1622810487bYfd1895`,
  "health-concerns-tag": `1622810422sYpq5301`,
  "occasional-experiences-tag": `1622810352wArv3744`,
  "acquisition-tag": `1622810254oLzy5287`,
  "parting-tag": `1622810170kFlp1649`,
  "early-pet-parenthood-tag": `1622810045zWob2087`,
  "adjusting-to-older-pets-tag": `1622809899tOfx0265`,
  "everyday-life-tag": `1622809633eBpn69`
}

const lowercaseWord = (word) => {
  return word.toLowerCase();
}

const checkIfDog = (petSpecies) => {
  return lowercaseWord(petSpecies) === `dog`;
}

const JourneyTemplateSVG = ({ species=`cat`, stageContentBlocks }) => {

  if (typeof window === `undefined`) {
    return(<></>);
  }

  let { isDesktop } = useBreakpoints({
    isDesktop: {min: 761, max: null}
  });

  const dispatch = useDispatch();
  const journeyContainer = useRef(null);

  const [stageMenuOpen, setStageMenuOpen] = useState(false);
  const openStageMenu = (e) => setStageMenuOpen(true);
  const closeStageMenu = (e) => {
    setStageMenuOpen(false);
    stageBtn.current.focus();
  };

  const stageBtn = useRef(null);

  useEffect (() => {
    if (journeyContainer.current !== null) {
      const journeyContainerStageLabels = journeyContainer.current.getElementsByClassName(`tooltip-wrapper`);
      if (typeof journeyContainerStageLabels === `object`) {
        [...journeyContainerStageLabels].forEach((stageLabel) => {
          const openModal = (stageLabelEvent) => {
            stageLabelEvent.stopPropagation();
            const stageContentBlock = stageContentBlocks.find((stageContent) => {
              return stageContent.classNameTitle + `-tag` === stageLabelEvent.target.closest(`.tooltip-wrapper`).id;
            });
            if (typeof(stageContentBlock) === `object`) {
              dispatch(actions.openStage(stageContentBlock))
            }
          }

          const openUserPilotTooltip = (stageLabelEvent) => {
            // Limit the number of click events to one.
            stageLabelEvent.stopPropagation();

            const stageContentBlock = stageLabelEvent.target.closest(`g[id$="-tag"]`);
            const userPilotTooltipIds = checkIfDog(species) ? USERPILOT_DOG_TOOLTIPS_IDS : USERPILOT_CAT_TOOLTIPS_IDS;

            window.userpilot.trigger(userPilotTooltipIds[stageContentBlock.id]);
          }

          const labelBlocks = stageLabel.querySelector(`[tabindex="0"]`);
          const plusIcons = stageLabel.querySelector(`[data-name="tooltip"]`);

          if (isDesktop) {
            stageLabel.removeEventListener(`click`, openModal);
            labelBlocks?.addEventListener(`click`, openUserPilotTooltip);
            plusIcons?.addEventListener(`click`, openUserPilotTooltip);
          } else {
            stageLabel.addEventListener(`click`, openModal);
            labelBlocks?.removeEventListener(`click`, openUserPilotTooltip);
            plusIcons?.removeEventListener(`click`, openUserPilotTooltip);
          }

          stageLabel.addEventListener(`keyup`, stageLabelEvent => {
            // Limit the number of keyup events to one.
            stageLabelEvent.stopPropagation();
            if (stageLabelEvent.keyCode === 13) { // Enter
              if (isDesktop) {
                const stageContentBlock = stageContentBlocks.find((stageContent) => {
                  return stageContent.classNameTitle + `-tag` === stageLabelEvent.target.closest(`.tooltip-wrapper`).id;
                });

                navigate(stageContentBlock.url);
              } else {
                openModal(stageLabelEvent);
              }
            }
          });
        })
      }
    }
  }, [isDesktop]);

  const graphicRendering = (desktopBreakpoint) => {
    if (desktopBreakpoint) {
      return (
        <div className="mars-container" ref={journeyContainer}>
          { checkIfDog(species) ? <DogSVG /> : <CatSVG /> }
          <button
            onClick={() =>
              dispatch(actions.openViewJourney(species))
            }
            className="mars-button">
            <MarsIcon icon="download" width="16px" height="16px" />
            <span className="mars-button__text">Download Journey</span>
          </button>
        </div>
      )
    } else {
      return (
        <div className="mars-container" ref={journeyContainer}>
          <p className="mars-body-large mars-text--center mars-padding-x--xl">Use the hotspots to navigate to each stage</p>
          { checkIfDog(species) ? <MobileDogSVGM /> : <MobileCatSVGM /> }
          <button ref={stageBtn} onClick={openStageMenu} aria-label="View stages" className="mars-button mars-button--wide mars-margin-top--xl">View stages</button>
          <StageMenu isOpen={stageMenuOpen} onClose={closeStageMenu} species={species} stageContentBlocks={stageContentBlocks} />
        </div>
      )
    }
  }

  return (
    <div className={classnames(`mars-journey-template-svg__wrapper`)}>
      <span className="mars-journey__bg-left-top" />
      <span className="mars-journey__bg-right-top" />
      <span className="mars-journey__bg-left-bottom" />
      <span className="mars-journey__bg-right-bottom" />
      {graphicRendering(isDesktop)}
    </div>
  );
};

export default JourneyTemplateSVG;
