import React from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';
import MsFormsModal from '@components/molecules/MsFormsModal/MsFormsModal';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './OpenMsFormButton.scss';

const OpenMsFormButton = ({ formUrl }) => {
  const dispatch = useDispatch();

  const openMsForms = () => {
    dispatch(actions.openMsForms());
  };

  return (
    <>
      <div
        className="mars-button mars-button--icon-button mars-button--ms-forms"
        onClick={openMsForms}>
        <div className="mars-icon-button__icon-wrapper mars-margin-right--xxs">
          <MarsIcon
            className="mars-icon-button__icon mars-icon--brand4"
            icon="feedback"
            width="24px"
            height="24px"
          />
        </div>
      </div>
      <MsFormsModal formUrl={formUrl} />
    </>
  );
};

export default OpenMsFormButton;
