import React from 'react';
import { graphql } from 'gatsby';

import munger from './journey-page.munger';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import JourneyStageTemplate from '@components/templates/JourneyStageTemplate/JourneyStageTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const JourneyPage = ({ data, location }) => {
  const journeyPageJson = munger(data);
  const page_species = location.pathname.startsWith(`/cat`) ? `cat` : `dog`;

  return (
    <Layout location={location}>
      <SEO title={journeyPageJson.intro.title} />
      <GTMPageLoad
        {...{
          page_type: `journey`,
          page_taxonomy_field: `Journey Page`,
          page_species,
          page_name: journeyPageJson.intro.title
        }}
      />
      <JourneyStageTemplate data={journeyPageJson} />
    </Layout>
  );
};

export const query = graphql`
  query JourneyPage($PageId: String!) {
    taxonomyTermSpeciesJson(drupal_id: { eq: $PageId }) {
      name
      description {
        value
      }
      relationships {
        field_content_block {
          field_content_block_title
          field_content_block_text
          field_content_block_image_side
          field_content_block_cta {
            title
            url
          }
          relationships {
            field_image_with_options {
              relationships {
                field_image {
                  relationships {
                    image {
                      uri {
                        url {
                          id
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        node__stage {
          title
          field_description {
            value
          }
          relationships {
            node__moment {
              drupal_id
            }
            field_stage_image {
              relationships {
                image {
                  uri {
                    url {
                      id
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            field_stage_secondary_image {
              relationships {
                field_media_image {
                  uri {
                    url {
                      id
                      publicURL
                    }
                  }
                }
              }
            }
          }
          path {
            alias
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContentJson(
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        menu_name
        link {
          url
        }
      }
    }
  }
`;

export default JourneyPage;
