import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '@state/actions';
import Modal from '@components/molecules/Modal/Modal';

const MsFormsModal = ({ formUrl }) => {
  const dispatch = useDispatch();
  const msForms = useSelector((state) => state.msForms);
  const isOpen = msForms.modal.isOpen;

  const closeModal = () => {
    dispatch(actions.closeMsForms());
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeModal}
      className="mars-modal--dialog mars-modal--ms-forms mars-text--center">
      <iframe
        width="100%"
        height="480px"
        src={formUrl}
        frameBorder="0"
        marginWidth="0"
        marginHeight="0"
        style={{ maxWidth: `640px` }}></iframe>
    </Modal>
  );
};

export default MsFormsModal;
