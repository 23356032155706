export default ({
  taxonomyTermSpeciesJson,
  allMenuLinkContentMenuLinkContentJson
}) => {
  const { name, description, relationships } = taxonomyTermSpeciesJson;
  const { node__stage, field_content_block } = relationships;

  const linkOrder = allMenuLinkContentMenuLinkContentJson.nodes.reduce(
    (justLinks, menuItem) => {
      if (menuItem.menu_name.toLowerCase() === name.toLowerCase())
        justLinks.push(menuItem.link.url);
      return justLinks;
    },
    []
  );

  node__stage.sort((a, b) => {
    return linkOrder.indexOf(a.path.alias) - linkOrder.indexOf(b.path.alias);
  });

  const stageBlocks = node__stage.map((block) => {
    return {
      title: block.title,
      description: block.field_description && block.field_description.value,
      numberOfTopics: block.relationships.node__moment
        ? block.relationships.node__moment.length
        : 0,
      url: block.path.alias,
      image: {
        path:
          block.relationships.field_stage_image &&
          block.relationships.field_stage_image.relationships.image.uri.url,
        alt: `Image alt text`
      },
      secondaryImage: {
        path:
          block.relationships.field_stage_secondary_image &&
          block.relationships.field_stage_secondary_image.relationships
            .field_media_image.uri.url,
        alt: `Image alt text`
      },
      alignment: `horizontal-scroll`,
      classNameTitle: block.title.toLowerCase().replace(/[\W_]+/g, `-`),
      species: name.toLowerCase()
    };
  });

  const contentBlock = (block) => ({
    title: block.field_content_block_title,
    description: block.field_content_block_text,
    alignment: `image-${block.field_content_block_image_side}`.toLowerCase(),
    ctas: block.field_content_block_cta,
    image: {
      path: block.relationships.field_image_with_options[0].relationships
        .field_image.relationships.image.uri.url,
      alt: block.field_content_block_title
    },
    species: name.toLowerCase()
  });

  return {
    intro: {
      title: name,
      description: description && description.value,
      ctaUrl: `${name.toLowerCase()}_journey.pdf`,
      ctaText: `View journey`
    },
    stageContentBlocks: stageBlocks,
    contentBlock: contentBlock(field_content_block[0])
  };
};
